import React, { useEffect, useState } from 'react'
import HomeSectionFive from '@PagesComponent/Home/HomeSections/HomeSectionFive';
import { GetStartedFourthStyle } from './GetStartedProductPage.style';
import { useRouter } from 'next/router';
import { addToCartMethod, _getAllProduct } from '../CommonMethods';

const GetStartedFourth = (props) => {
    const [productData, setProductData] = useState()
    const [cartNotify, setCartNotify] = useState('');
    const [errorMsg, setErrorMsg] = useState('');

    useEffect(() => {
        const category = 3;
        _getAllProduct(false, category).then((res) => {
            let OBJECT = res?.find(obj => obj?.category_id === 3);
            setProductData(OBJECT)
        })
    }, [])

    function addProduct() {
        addToCartMethod(
            {
                id: productData?.id,
                quantity: 1,
                is_autoship: false
            },
            props?.setshowminicart,
            setCartNotify,
            props?.cartdata?.products,
            props?.setCartData,
            false,
            setErrorMsg,
        )
    }
    return (
        <GetStartedFourthStyle>
            <div className="main_fouth_section">
                <div className='getStartedfouth_first'>
                    <h3>Your custom formula can be generated<br /> once your DNA reports are ready.</h3>
                    <h3>Order your DNA test and reports <span onClick={() => addProduct()} style={{ cursor: 'pointer' }}>now</span> to begin.</h3>
                </div>
                <div className='getStartedfouth_second'>
                    <HomeSectionFive />
                </div>
            </div>
            <div className="bottom-img"><img src="/images/molecule-bottom.png" /></div>
        </GetStartedFourthStyle>
    )
}

export default GetStartedFourth